/**
 * Styles targeting the Spotlight content type
 *
 */

 /* =Spotlight Teaser Sections
 ----------------------------------------------------------*/
 
 .related-spotlight-teasers {
  background: $wcm-bg-gray;
  margin: 20px 0 0;
  padding: 30px 0;
  .view-header h3 {
    text-align: center;
    margin: 0 0 30px;;
  }
  .view-content {
     @include container-wrap();
   }
   .view-footer {
     .btn--wcm {
        padding-right: 35px;
     }
   }
 }

.spotlight {
  background: $wcm-white;
  border: 1px solid $wcm-border-gray;
  text-align: center;
  margin: 0 0 30px;
  padding: 20px;
  height: 100%;
}

.spotlight__image {
  margin: 10px 0;
  padding: 20px;
  overflow: hidden;
  img {
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.spotlight__list-view {
  text-align: left;
  .row.is-flex {
    align-items: center;
  }
  .spotlight__image {
    padding: 0;
  }
  .spotlight__title {
    font-size: 20px;
    font-family: $wcm-bold;
    color: $wcm-red;
  }
  .spotlight__program {
    color: #666;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.3;
    font-family: $wcm-bold;
    letter-spacing: 2px;
    margin: 10px 0;
  }
}

.spotlight__program span {
  font-weight: 700;
}

.spotlight__teaser {
  font-size: 14px;
}

/* =Spotlight Full Node
----------------------------------------------------------*/

.panel-wcm-hpr-spotlight .sidebar .pane-entity-field {
  margin: 0 0 10px;
  .field-label {
    font-weight: 700;
  }
}

.pane-node-field-spotlight-type {
  font-weight: 700;
}