/**
 * Styles targeting the home page.
 *
 */

 /* =Home Panels
 ----------------------------------------------------------*/
 
 .hpr-home-panels {
   @include breakpoint(( min: 1400px )) {
     max-width: 1400px;
     margin: 0 auto;
   }
 }
 
 .hpr-home-panel {
  position: relative;
  overflow: hidden;
  display: none;
  @include breakpoint($md) {
    display: block;
  }
  &:hover {
    .hpr-home-panel__top-links {
      transform: translate(0, 0);
    }
  } 
 }

 .hpr-home-panel__image img {
   width: 100%;
   height: auto;
 }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: $wcm-dark-orange;
}

.hpr-home-panel__top-links {
  position: absolute;
  color: $wcm-white;
  z-index: 100;
  width: 90%;
  transition: 0.25s;
  transform: translate(0,-100%);
  ul {
    margin: 10px 0 0;
    padding: 0 0 0 20px;
    @include breakpoint($lg) {
      margin: 30px 0 0;
      padding: 0 0 0 30px;
    }
  }
  li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 0 0 1px;
    position: relative;
  }
  a {
    display: block;
    padding: 2px 15px 2px 8px;
    border: none;
    color: #fff;
    font-size: 14px;
    @include breakpoint($lg) {
      font-size: 15px;
      padding: 5px 20px 5px 8px;
    }
    @include breakpoint(( min: 1400px )) {
      padding: 10px 20px 10px 8px;
    }
    &:after {
      content: '\e80d';
      font-size: 60%;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
      position: absolute;
      top: 33%;
      right: 5px;
    }
    &:hover {
      background: $wcm-red;
    }
  }
}

.hpr-home-panel__text {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  color: $wcm-white;
  opacity: 0.87;
  background-color: $wcm-dark-orange;
  text-align: center;
  z-index: 100;
  transition: height 0.25s;
  .hpr-home-panel__title {
    @include v-center();
    > a {
      display: block;
      font-size: 20px;
      line-height: 1.3;
      font-family: $wcm-bold;
      color: $wcm-white;
      position: relative;
      z-index: 100;
      margin: 0 30px;
      border-bottom: 1px solid $wcm-dark-orange;
      @include breakpoint($lg) {
        font-size: 24px;
      }
    }
  }
}

.hpr-home-panel__intro {
  transition: 0.25s;
  transform: translate(0, 200%);
  padding: 10px 20px;
  font-size: 13px;
  @include breakpoint($lg) {
    padding: 10px 30px 30px;
    font-size: 15px;
  }
}

/* =Hover Interaction
----------------------------------------------------------*/

.hpr-home-panel {
  &:hover {
    .mask {
      opacity: 0.87; 
    }
    .hpr-home-panel__top-links {
      transform: translate(0, 0);
    }
    .hpr-home-panel__text {
      height: auto;
      max-height: auto;
      background-color: transparent;
    }
    .hpr-home-panel__title {
      top: 0;
      transform: translateY(0);
      > a {
        border-bottom: 1px dotted $wcm-white;
      }
    }
    .hpr-home-panel__intro {
      transform: translate(0, 0);
    }
  } 
 }

/* =Simplified Home Panels for Mobile Devices
----------------------------------------------------------*/

.hpr-home-panels--sm {
  @include breakpoint($md) {
    display: none;
  }
}

.hpr-home-panel--sm {
  background: $wcm-white;
  border: 1px solid $wcm-border-gray;
  margin: 0 0 30px;
  padding: 20px;
  .row.is-flex {
    align-items: center;
  }
}

.hpr-home-panel--sm__image {
  margin: 10px 0;
}

.hpr-home-panel--sm__title {
  font-size: 20px;
  font-family: $wcm-bold;
  color: $wcm-red;
}

.hpr-home-panel--sm__teaser {
  font-size: 14px;
}

/* =Director's Message
----------------------------------------------------------*/

.hpr-chair-callout {
  margin: 30px 0;
  .row.is-flex {
    align-items: center;
  }
  h3 {
    margin: 0 0 20px;
  }
}

.hpr-chair-callout__img {
  overflow: hidden;
  img {
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

