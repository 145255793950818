/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */

/* =Callouts
----------------------------------------------------------*/

.paragraphs-items-field-card-callout {
  @include breakpoint($md) {
    display: flex;
  }
}

.card-callout {
  margin: 20px 0;
  padding-bottom: 20px;
  @include breakpoint($md) {
    border: none;
    padding-bottom: 0;
    overflow: hidden;
  }
}

.card-callout__wrapper {
  background: $wcm-bg-gray;
  @include breakpoint($md) {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
}

.card-callout__text {
  padding: 10px;
}

.card-callout__title {
  margin: 0 0 10px;
  text-align: center;
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-bright-orange;
      text-decoration: underline;
      &:before {
        text-decoration: none;
      }
    }
    &:after {
      content: '' !important;
      display: none !important;
    }
  }
  a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px;
  }
  a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: $wcm-bright-orange;
    background: $wcm-bg-gray;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    @include fontello();
    line-height: 50px;
    display: inline-block;
    @include transition(background-color .25s);
  }
  a.external-link span:before {
    content: '' !important;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  a:hover span:before {
    text-decoration: none;
  }
}

.card-callout__image {
  overflow: hidden;
}

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
} 

/* =Icon Callouts
----------------------------------------------------------*/

.icon-callout {
  margin: 30px 0;
}

.icon-callout__link {
  text-align: center;
  @include breakpoint($sm) {
    //padding: 40px 30px;
    margin: 0;
  }
}

.icon-callout__link a {
  position: relative;
  display: block;
  z-index: 10;
  padding: 50px 30px;
  border: none;
  // Put the box shadow on a psuedo-element
  // http://tobiasahlin.com/blog/how-to-animate-box-shadow/
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
  }
  &:hover {
    z-index: 1000;
    text-decoration: none;
    transform: scale(1.1, 1.1);
    border-width: 1px;
    background: $wcm-white;
  }
  &:hover:after {
    opacity: 1;
  }
}

.icon-callout__detail {
  width: 80%;
  @include center();
}

.icon-callout__link a span {
  display: block;
  text-align: center;
}

.icon-callout__link.col-sm-12 a {
  border: none;
}

.icon-callout__link.col-sm-6:nth-child(1) a {
  @include breakpoint($sm) {
    border-right: 1px solid $wcm-border-gray;
  }
}

.icon-callout__link.col-sm-4:nth-child(1) a,
.icon-callout__link.col-sm-4:nth-child(2) a {
  @include breakpoint($sm) {
    border-right: 1px solid $wcm-border-gray;
  }
}

.icon-callout__link.col-sm-3:nth-child(1) a,
.icon-callout__link.col-sm-3:nth-child(2) a,
.icon-callout__link.col-sm-3:nth-child(3) a {
  @include breakpoint($sm) {
    border-right: 1px solid $wcm-border-gray;
  }
}

.icon-callout__icon .cta-icon {
  width: 40px;
  height: 40px;
  fill: #e87722;
  color: #cf4520;
}

.icon-callout__text > a.external-link:after,
.icon-callout__icon > a.external-link:after {
  content: '';
  padding-left: 0;
  background: none;
}

/* =Program Contacts
----------------------------------------------------------*/

.field-rprogram-contacts {
  .field-label {
    margin: 0 0 5px;
  }
}

.field-program-contacts-email {
  margin: 0 0 10px;
}