/**
 * Styles targeting the Event content type displays
 *
 */

 /* =Home Event Flip Cards
 ----------------------------------------------------------*/

.hpr-home-events {
  position: relative;
  h2 {
    margin: 10px 0;
  }
  .see-all {
    @include breakpoint($md) {
      position: absolute;
      top: 12px;
      right: 50px;
    }
  }
}
 
.hpr-home-events-panel {
  background-image: url(../images/home_panels/home-events-bg.jpg);
  background-size: cover;
  padding: 60px 30px;
  .row.is-flex {
    display: block;
    @include breakpoint($md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.hpr-home-event {
  margin: 0 0 20px;
  @include breakpoint($md) {
    margin: 0;
  }
}

.home-event__date {
  width: 120px;
  margin: 0 auto 10px;
}

.home-event__date__circle {
  font-family: $wcm-bold;
  background: $wcm-border-gray;
  display: table-cell;
  height: 120px;
  width: 120px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  span {
    display: block;
  }
}

.home-event__date___day {
  font-size: 30px;
}

.home-event__date___month {
  font-size: 18px;
  position: relative;
  line-height: 0.5;
}

.home-event__title {
  font-size: 14px;
  font-weight: 700;
  color: $wcm-red;
}

.home-event__detail {
  margin: 0 0 10px;
}

.home-event__detail:before {
  content: '';
  height: 20px;
  width: 30px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.home-event__location:before {
  background: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/location.svg') center left no-repeat;
  background-size: 20px 20px;
}

.home-event__full-date:before {
  background: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg') center left no-repeat;
  background-size: 20px 20px;
}

.home-event__time:before {
  background: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/appointment.svg') center left no-repeat;
  background-size: 20px 20px;
}

.home-event__link {
  margin: 30px 0 0;
  .btn {
    padding: 4px 20px;
    &:hover {
      background: $wcm-red;
    }
  }
}

/* =Flip Interaction
----------------------------------------------------------*/

.wcm-flip-card {
  perspective: 1000px;
}

.wcm-flip-card.flip .wcm-flip-card__action {
  transform: rotateY(180deg);
}

.wcm-flip-card,
.wcm-flip-card__front,
.wcm-flip-card__back {
  width: 100%;
  //min-height: 250px;
}

/* flip speed goes here */
.wcm-flip-card__action {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
}

/* hide back of pane during swap */
.wcm-flip-card__front,
.wcm-flip-card__back {
  backface-visibility: hidden;
  padding: 30px 20px;
}

/* front pane, placed above back */
.wcm-flip-card__front {
  transform: rotateY(0deg);
  background: $wcm-white;
  z-index: 2;
  position: relative;
  text-align: center;
  min-height: 250px;
  &:before {
    content: '';
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/information.svg') center left no-repeat;
    background-size: 20px 20px;
  }
}

/* back, initially hidden pane */
.wcm-flip-card__back {
  background: #F5F5F5;
  transform: rotateY(-180deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:before {
    content: '';
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/close.svg') center left no-repeat;
    background-size: 20px 20px;
  }
} 

 /* =Upcoming Events List
 ----------------------------------------------------------*/

 .event-list-item {
   @include clearfix();
   margin: 20px 0 0;
 }

 .event-list-item:last-child {
   margin-bottom: 20px;
 }

 .event-list-item__date {
  float: left;
  width: 12%; 
  font-size: 18px;
  font-family: $wcm-bold;
  color: $wcm-dark-gray;
  border-right: 1px solid $wcm-border-gray;
 }

 .event-list-item__title {
  float: left;
  width: 88%;
  padding-left: 18px;
 }
 
 /* =Calendar Pager
 ----------------------------------------------------------*/

.wcm-calendar__pager {
  margin: 20px 0;
  .date-nav {
    padding: 0;
  }
}

.item-list .pager--wcm-calendar {
  @include breakpoint($xs) {
    margin: 20px 0;
    padding: 0;
    text-align: left;
  }
}

.view .wcm-calendar__pager .date-heading {
  text-align: left;
}

.view .wcm-calendar__pager .date-prev,
.view .wcm-calendar__pager .date-next {
  @include breakpoint($xs) {
    position: static;
    margin: 0 10px 0 0;
  }
  border: none;
  background: none;
  padding: 0;
  a {
    color: #fff;
    background-color: $wcm-dark-orange;;
    border-color: $wcm-dark-orange;
    font-weight: normal;
    @include breakpoint($xs) {
      margin: 0;
    }
    &:hover {
      background-color: $wcm-red;;
      border-color: $wcm-red;
    }
  }
}

.view .wcm-calendar__pager .date-prev {
  right: 85px;
}

/* =Calendar Page Grid
----------------------------------------------------------*/

.wcm-calendar__page {
  .calendar-calendar td.empty {
    color: #777;
  }
  .calendar-calendar .month-view .full td.date-box.today,
  .calendar-calendar .month-view .full tr td.single-day.today {
    border-color: #aaa;
  }
  .calendar-calendar .month-view .full td.single-day div.monthview {
    border-left: 3px solid $wcm-bright-orange;
    padding: 5px;
  }
  .calendar-calendar .month-view .full .inner .monthview {
    border-radius: 0px;
  }
}

/* =Calendar Footer
----------------------------------------------------------*/

.wcm-calendar__footer {
  margin: 30px 0;
}

.wcm-calendar__footer__links {
  a {
    display: block;
    margin: 10px 0;
    border: none;
    &:before {
      content: '';
      height: 20px;
      width: 30px;
      display: inline-block;
      position: relative;
      top: 5px;
      background: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg') center left no-repeat;
      background-size: 20px 20px;
    }
  }
}

.wcm-calendar__footer__contact {
  > strong {
    color: $wcm-dark-orange;
  }
}

/* =Calendar Responsive Styles (from Ken J.)
----------------------------------------------------------*/

/*CALENDAR MOBILE VIEW*/
/*NAVIGATION*/
#cal-nav-container{
    h3.cal-title{
        text-align: center;
        padding-bottom: 10px;
        a{
            padding: 0px 50px;
            text-decoration: none;
            @media all and (max-width: 768px){
                padding: 0px 5px;
            }
        }
        a:after{
                content: none;
        }
    }
}
.calendar-calendar {
    .month-view .full td.single-day div.monthview{
        //background: $wcm-yellow;
        padding: 5px;
    }
    td.empty {
        color: $wcm-med-gray;
    }
    .month-view .full td.date-box.today {
        border-width: 2px 2px 0px 2px;
        border-style: solid;
        border-color: $wcm-dark-orange;
        @media all and (max-width: 768px){
            border: none;
        }
    }
    .month-view .full tr td.single-day.today {
        border-bottom: 2px solid $wcm-dark-orange;
        @media all and (max-width: 768px){
            border: none;
        }
    }
    .month-view{
        .full {
            tr td.today{
                background: none;
                border-left: 2px solid $wcm-dark-orange;
                border-right: 2px solid $wcm-dark-orange;
            }
            td.multi-day {
                div.monthview{
                    color: $wcm-dark-orange;
                    //background: $wcm-yellow;
                }
            }
        }
    }
    td .inner div.day a {
        color: $wcm-dark-orange;
    }
}
@media all and (max-width: 768px) {
/* BEGIN Calendar responsive CSS - tablet portrait */
  /* Force table to not be like tables anymore */
  .calendar-calendar table,
  .calendar-calendar tbody,
  .calendar-calendar th,
  .calendar-calendar td,
  .calendar-calendar tr  { 
    display: block; 
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .calendar-calendar thead,
  .calendar-calendar thead tr,
  .calendar-calendar tr.date-box { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .calendar-calendar tr td.no-entry  { 
    border-bottom: 1px solid #ccc; 
  }
  .calendar-calendar td:before{   /* Format & Label the day headings */
    /* Now like a table header */
    width: 100%;  
    white-space: nowrap;
    clear: both;
    text-align: center;
    display: block;
    box-sizing: border-box;
    color: $wcm-white;
    background-color: #888;
    padding: 1px;
    content: attr(data-label); 
    height: auto;
  }
  .calendar-calendar td.today,
  .calendar-calendar .full tr td.single-day.today{
    border-top: 2px solid #0074ab;
    -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  }
  .calendar-calendar .full td, .calendar-calendar .month-view .full td, .calendar-calendar .week-view .full td, .calendar-calendar .day-view td{
    padding: 0;
  }
  .calendar-calendar td,
  .calendar-calendar td.calendar-agenda-items  { 
    /* Behave  like a "row" */
    border: none;
    position: relative;
    width: 100%;
  }
  /*.calendar-calendar td.past{
    display: none;
  }*/
  .calendar-calendar td.no-entry,
  .calendar-calendar td.empty{
    display: none;
  }
  .calendar-calendar td.calendar-agenda-items div.calendar{
    padding: 0;
  }
  .calendar-calendar div.calendar,
  .calendar-calendar .date-display-single,
  .calendar-calendar td span.date-display-single  {
    font-size: 14px;
    font-weight: normal;
    margin: 0 !important;
  }
  .calendar-calendar .inner .item{
    padding: 10px 10px 0;
  }
  .calendar-calendar .inner .item:last-child{
    padding-bottom: 10px;
  }
  .calendar-calendar .full tr.single-day .no-entry{
    height: 44px !important;
    line-height: 24px;
  }
  .calendar-calendar .full tr.single-day .no-entry .inner,
  .calendar-calendar .no-entry .inner{
    height: auto !important;
    line-height: 1px;
  }
  .calendar-calendar .inner .views-field-title-field{
    display: inline-block;
  }
  /* END Calendar responsive CSS - tablet portrait */
}



