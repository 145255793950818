/**
 * Styles targeting the Division content type
 *
 */

 /* =Division Full Node View
----------------------------------------------------------*/

.pane-node-field-division-image {
  margin: 0 0 20px;
}

.paragraphs-item-division-chief-messages {
  margin: 20px 0;
  h3 {
    margin: 0 0 20px;
  }
}

.panel-hpr-division .secondary-content {
  .pane-title {
    margin-bottom: 20px;
  }
}

.pane-profiles-panel-pane-8 {
  border-top: 1px solid $wcm-border-gray;
}

.pane-node-field-division-contact-info {
  font-size: 13px;
  margin: 10px 0 0;
}

.tertiary-content {
  margin: 20px 0;
  .pane-title {
    margin-top: 0;
  }
}

.pane-career-opportunities-panel-pane-2 {
  background: $wcm-bg-gray;
  padding: 30px 30px 30px;
  .pane-title {
    margin-bottom: 20px;
  }
}

.pane-events-panel-pane-2 {
  padding: 30px 0;
}
  
 
 