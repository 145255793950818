/**
 * Styles targeting the News Items
 *
 */

 /* =News Teasers
 ----------------------------------------------------------*/
 
.news-teaser {
  margin: 0 0 30px;
  padding: 0 0 20px;
  border-bottom: 1px solid $wcm-border-gray;
  @include breakpoint($md) {
    .row.is-flex {
      align-items: center;
    }
  }
}
.news-teaser:first-child {
  margin-top: 20px;
}
.news-teaser:last-child {
  border-bottom: none;
}

.news-teaser__image {
  overflow: hidden;
  padding: 0 0 0 10px;
}

.news-teaser__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
} 

.news-teaser__title {
  font-size: 16px;
  a:hover {
    text-decoration: underline;
  }
}
.news-teaser__date {
  color: $wcm-med-gray;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  margin: 10px 0;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
}
.news-teaser__category {
  font-size: 13px;
  padding-left: 25px;
  position: relative;
  margin: 20px 0 0;
  &:before {
    content: '';
    background: url(../images/tag.svg);
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0;
  }
}

/* =Home News
----------------------------------------------------------*/

.hpr-home-news {
  margin: 20px 0 0;
}

/* =News Callout (above index)
----------------------------------------------------------*/

.hpr-news-callout {
  padding: 20px 0;
  margin: 20px 0;
  border: 2px solid $wcm-border-gray;
  border-width: 2px 0;
  .row.is-flex {
    display: block;
    align-items: center;
    @include breakpoint($md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .wcm-cta__tile {
    width: auto;
    height: auto;    
    border-width: 0 0 0 0;
    @include breakpoint($md) {
      height: 80px;
      border-width: 0 1px 0 0;
    }
    span {
      text-align: left;
      @include breakpoint($md) {
        text-align: center;
      }
    }
  }
  .wcm-cta__detail {
    position: relative;
    transform: none;
    width: auto;
    left: 0;
    @include breakpoint($md) {
      width: 80%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  h3 {
    @include breakpoint($md) {
      padding-left: 20px;
      margin: 0;
    }
  }
  p {
    @include breakpoint($md) {
      padding-left: 20px;
    }
  }
}

/* =News Full NOde Article
----------------------------------------------------------*/

.field-name-field-news-category {
  li {
    &:before {
      content: '';
      height: 20px;
      width: 30px;
      display: inline-block;
      position: relative;
      top: 5px;
      background: url(../images/tag.svg) center left no-repeat;
      background-size: 20px 20px;
    }
  }
}




