/**
 * Styles targeting various Landing Callouts
 *
 */

 /* =Graduate Program Grid
 ----------------------------------------------------------*/
 
.hpr-programs-grid {
  background: $wcm-bg-gray;
}

.hpr-programs-grid__title {
  background: $wcm-dark-orange;
  color: $wcm-white;
  font-family: $wcm-bold;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 8px 0;
  text-align: center;
}

.hpr-programs-grid__list {
  background: $wcm-white;
}

.hpr-programs-grid__list-item {
  padding: 30px;
  border: 1px solid $wcm-border-gray;
  height: 100%;
}

/* =Band Callout
----------------------------------------------------------*/

.hpr-band-callout {
  margin: 30px 0;
  border: 1px solid $wcm-border-gray;
  .row.is-flex {
    align-items: center;
  }
  h3 {
    margin-top: 0;
  }
}

.hpr-band-callout__img {
  overflow: hidden;
  img {
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.hpr-band-callout__text {
  padding: 0 20px;
}

/* =Grad Programs Resources
----------------------------------------------------------*/

.grad-programs-resources {
  h4 {
    margin-bottom: 15px;
  }
  .wcm-cta {
    width: 100%;
    margin: 20px 0;
  }
  .wcm-cta__tile {
    width: 50%;
  }
}

/* =Misc Overrides
----------------------------------------------------------*/

.panel-wcm-hpr-landing img.panopoly-image-full {
  margin: 0;
}
 
 