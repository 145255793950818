/**
 * Styles targeting various components such as the breadcrumbs or sidebar items
 *
 */

/* =Primary Navigation
----------------------------------------------------------*/

#primary-nav > .menu {
  display: flex;
  justify-content: space-between; 
}

#primary-nav .level-1 {
  width: auto;
  font-size: 13px;
  line-height: 1.3;
  @include breakpoint($md) {
    font-size: 14px;
  }
  @include breakpoint($lg) {
    width: auto;
    font-size: 16px;
  }
}

/* =Drawer Navigation
----------------------------------------------------------*/

#drawer-nav .level-1 > .menu {
  @include breakpoint($sm) {
    display: flex;
    flex-flow: row wrap;
  }
}

#drawer-nav .level-2 {
   @include breakpoint($sm) {
    padding: 10px 10px 0 0;
    width: 20%;
  }
}

// Deep nested items in spotlight section
#top-nav .menu-mlid-1973 .level-3 ul {
  display: none;
}

/* =Sidebar Social Media Callout
----------------------------------------------------------*/

 .wcm-cta {
  width: 220px;
  margin: 0 auto 20px;
}

.wcm-cta--social {
  text-align: center;
  font-weight: 700;
  margin: 0 auto 30px;
  .wcm-cta__tile {
    width: 30px;
    height: 30px;
    border: none;
    float: none;
    display: inline-block;
    margin-right: 10px;
    top: 10px;
    &:after {
      box-shadow: none;
    }
    .cta-icon {
      width: 30px;
      height: 30px;
    }
  }
}

/* =Career Opportunities
----------------------------------------------------------*/

.view-career-opportunities {
  .opportunity {
    margin: 0 0 30px;
    padding: 0 0 30px;
    border-bottom: 1px solid $wcm-border-gray;
  }
  .opportunity:last-child {
    border: none;
  }
}

/* =Landing Lists
----------------------------------------------------------*/

.landing-list {
  .landing-list-item {
    background: $wcm-bg-gray;
    @include breakpoint($sm) {
      border: 1px solid $wcm-border-gray;
      border-width: 0 1px 1px 0;
      @include clearfix();
    }
  }
  .landing-list-item:first-child {
    @include breakpoint($sm) {
      border-width: 1px 1px 1px 0;
      @include clearfix();
    }
  }
  .teaser__detail {
    padding: 20px;
    font-size: 13px;
    @include breakpoint($lg) {
      padding: 30px;
      font-size: 14px;
    }
    .landing-list-item__title {
      font-size: 18px;
      margin: 0 0 10px;
    }
  }
}

.teaser__image {
  overflow: hidden;
  img {
    transition: all 0.3s ease 0s;
    &:hover {
      @include breakpoint($md) {
        transform: scale(1.1);
      }
    }
  }
}

/* =View List Items
----------------------------------------------------------*/

 .view-list-item {
  margin: 0 0 20px;
  padding: 20px;
  border: 1px solid $wcm-border-gray;
  @include clearfix();
  .profile-list-image {
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .view-list-item__title {
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 5px;
  }
  .btn--small {
    margin: 10px 0;
  }
 }

.directory-entry__title ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    padding: 0 0 5px;
    margin: 0;
  }
}

.directory-entry__email {
  margin: 0 0 10px;
}

/* =News
----------------------------------------------------------*/

.research-highlight__category {
  margin: 20px 0 0;
}

/* =Research Programs
----------------------------------------------------------*/

.research-program-list {
  .teaser__image {
    padding: 30px 60px 30px 0;
  }
  .program {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $wcm-border-gray;
  }
  .program:last-child {
    border-bottom: none;
  }
}

/* =Course Lists
----------------------------------------------------------*/

.view-courses {
  .view-content > h3 {
    margin-bottom: 10px; 
  }
}

/* =Biblio / Publications
----------------------------------------------------------*/

.node-biblio {
  .submitted {
    display: none;
  }
}

.view-publications {
  .view-content > h3 {
    color: #000000;
    font-size: 15px;
    font-family: $font-family-sans-serif;
    line-height: 25px;
    font-weight: 700;
    background-color: #e1e1e1;
    border: 1px solid #ccc;
    padding: 7px;
    margin: 15px 0;
  }
  .publication {
    margin: 15px 0;
  }
}


/* =FAQ
----------------------------------------------------------*/

.q-and-a .q-and-a__label {
  font-weight: bold;
  color: $wcm-dark-gray;
}

/* =Course List
----------------------------------------------------------*/

.course__code {
  color: $wcm-med-gray;
  @include breakpoint($sm) {
    float: right;
    padding: 0 20px 0 0;
  }
}



/* =CTA Icons
----------------------------------------------------------*/

.wcm-cta__tile {
  &:nth-child(5) {
    border-width: 1px 1px 0 0;
  }
  &:nth-child(6) {
    border-width: 1px 0 0 0;
  }
}

/* =Footer
----------------------------------------------------------*/

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
  li {
    line-height: 1.4;
    padding: 0 0 8px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100% / 5);
    float: left;
    padding: 0 10px 5px;
  }
}

/* =Forms
----------------------------------------------------------*/

.views-exposed-form {
  background: $wcm-bg-gray;
  padding: 10px 20px;
  margin: 0 0 20px;
  .form-select {
    @include breakpoint($md) {
      max-width: none;
      margin-right: 45px;
    }
  }
}

.expand-toggle {
  font-size: 13px;
  margin: 30px 0 40px;
  button {
    background: $wcm-dark-orange;
    color: $wcm-white;
    border: none;
    border-radius: 20px;
    padding: 6px 20px;
    margin-right: 5px;
  }
}

/* =Page-specific Adjustments
----------------------------------------------------------*/

.pane-bundle-table .pane-title {
  font-size: 18px;
  color: $wcm-dark-gray;
  margin-bottom: 10px;
}

.pane-divisions-panel-pane-2 {
  margin: 0 0 30px;
}

/* =Misc
----------------------------------------------------------*/

.title-bar {
  @include breakpoint($xs) {
    line-height: 1.5
  }
}

.menu-mlid-1589 > a {
  @include breakpoint($xs) {
    width: 80%;
    display: inline-block;
  }
}

h3 a:hover {
  color: $wcm-dark-orange;
}

.teaser__image a {
  border-bottom: none;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}


 
 