/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */
/* =Callouts
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .paragraphs-items-field-card-callout {
    display: flex; } }

.card-callout {
  margin: 20px 0;
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .card-callout {
      border: none;
      padding-bottom: 0;
      overflow: hidden; } }

.card-callout__wrapper {
  background: #f7f7f7; }
  @media screen and (min-width: 992px) {
    .card-callout__wrapper {
      margin-bottom: -99999px;
      padding-bottom: 99999px; } }

.card-callout__text {
  padding: 10px; }

.card-callout__title {
  margin: 0 0 10px;
  text-align: center; }
  .card-callout__title a {
    color: #b31b1b; }
    .card-callout__title a:hover {
      color: #e7751d;
      text-decoration: underline; }
      .card-callout__title a:hover:before {
        text-decoration: none; }
    .card-callout__title a:after {
      content: '' !important;
      display: none !important; }
  .card-callout__title a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px; }
  .card-callout__title a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: #e7751d;
    background: #f7f7f7;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    line-height: 50px;
    display: inline-block;
    -webkit-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .card-callout__title a.external-link span:before {
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
  .card-callout__title a:hover span:before {
    text-decoration: none; }

.card-callout__image {
  overflow: hidden; }

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .card-callout__image img:hover {
    transform: scale(1.1); }

/* =Icon Callouts
----------------------------------------------------------*/
.icon-callout {
  margin: 30px 0; }

.icon-callout__link {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .icon-callout__link {
      margin: 0; } }

.icon-callout__link a {
  position: relative;
  display: block;
  z-index: 10;
  padding: 50px 30px;
  border: none; }
  .icon-callout__link a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0; }
  .icon-callout__link a:hover {
    z-index: 1000;
    text-decoration: none;
    transform: scale(1.1, 1.1);
    border-width: 1px;
    background: #fff; }
  .icon-callout__link a:hover:after {
    opacity: 1; }

.icon-callout__detail {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.icon-callout__link a span {
  display: block;
  text-align: center; }

.icon-callout__link.col-sm-12 a {
  border: none; }

@media screen and (min-width: 768px) {
  .icon-callout__link.col-sm-6:nth-child(1) a {
    border-right: 1px solid #dddddd; } }

@media screen and (min-width: 768px) {
  .icon-callout__link.col-sm-4:nth-child(1) a,
  .icon-callout__link.col-sm-4:nth-child(2) a {
    border-right: 1px solid #dddddd; } }

@media screen and (min-width: 768px) {
  .icon-callout__link.col-sm-3:nth-child(1) a,
  .icon-callout__link.col-sm-3:nth-child(2) a,
  .icon-callout__link.col-sm-3:nth-child(3) a {
    border-right: 1px solid #dddddd; } }

.icon-callout__icon .cta-icon {
  width: 40px;
  height: 40px;
  fill: #e87722;
  color: #cf4520; }

.icon-callout__text > a.external-link:after,
.icon-callout__icon > a.external-link:after {
  content: '';
  padding-left: 0;
  background: none; }

/* =Program Contacts
----------------------------------------------------------*/
.field-rprogram-contacts .field-label {
  margin: 0 0 5px; }

.field-program-contacts-email {
  margin: 0 0 10px; }

/**
 * Styles targeting the News Items
 *
 */
/* =News Teasers
 ----------------------------------------------------------*/
.news-teaser {
  margin: 0 0 30px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd; }
  @media screen and (min-width: 992px) {
    .news-teaser .row.is-flex {
      align-items: center; } }

.news-teaser:first-child {
  margin-top: 20px; }

.news-teaser:last-child {
  border-bottom: none; }

.news-teaser__image {
  overflow: hidden;
  padding: 0 0 0 10px; }

.news-teaser__image img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .news-teaser__image img:hover {
    transform: scale(1.1); }

.news-teaser__title {
  font-size: 16px; }
  .news-teaser__title a:hover {
    text-decoration: underline; }

.news-teaser__date {
  color: #666666;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  margin: 10px 0;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px; }

.news-teaser__category {
  font-size: 13px;
  padding-left: 25px;
  position: relative;
  margin: 20px 0 0; }
  .news-teaser__category:before {
    content: '';
    background: url(../images/tag.svg);
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0; }

/* =Home News
----------------------------------------------------------*/
.hpr-home-news {
  margin: 20px 0 0; }

/* =News Callout (above index)
----------------------------------------------------------*/
.hpr-news-callout {
  padding: 20px 0;
  margin: 20px 0;
  border: 2px solid #dddddd;
  border-width: 2px 0; }
  .hpr-news-callout .row.is-flex {
    display: block;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .hpr-news-callout .row.is-flex {
        display: flex;
        flex-wrap: wrap; } }
  .hpr-news-callout .wcm-cta__tile {
    width: auto;
    height: auto;
    border-width: 0 0 0 0; }
    @media screen and (min-width: 992px) {
      .hpr-news-callout .wcm-cta__tile {
        height: 80px;
        border-width: 0 1px 0 0; } }
    .hpr-news-callout .wcm-cta__tile span {
      text-align: left; }
      @media screen and (min-width: 992px) {
        .hpr-news-callout .wcm-cta__tile span {
          text-align: center; } }
  .hpr-news-callout .wcm-cta__detail {
    position: relative;
    transform: none;
    width: auto;
    left: 0; }
    @media screen and (min-width: 992px) {
      .hpr-news-callout .wcm-cta__detail {
        width: 80%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%); } }
  @media screen and (min-width: 992px) {
    .hpr-news-callout h3 {
      padding-left: 20px;
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .hpr-news-callout p {
      padding-left: 20px; } }

/* =News Full NOde Article
----------------------------------------------------------*/
.field-name-field-news-category li:before {
  content: '';
  height: 20px;
  width: 30px;
  display: inline-block;
  position: relative;
  top: 5px;
  background: url(../images/tag.svg) center left no-repeat;
  background-size: 20px 20px; }

/**
 * Styles targeting the Division content type
 *
 */
/* =Division Full Node View
----------------------------------------------------------*/
.pane-node-field-division-image {
  margin: 0 0 20px; }

.paragraphs-item-division-chief-messages {
  margin: 20px 0; }
  .paragraphs-item-division-chief-messages h3 {
    margin: 0 0 20px; }

.panel-hpr-division .secondary-content .pane-title {
  margin-bottom: 20px; }

.pane-profiles-panel-pane-8 {
  border-top: 1px solid #dddddd; }

.pane-node-field-division-contact-info {
  font-size: 13px;
  margin: 10px 0 0; }

.tertiary-content {
  margin: 20px 0; }
  .tertiary-content .pane-title {
    margin-top: 0; }

.pane-career-opportunities-panel-pane-2 {
  background: #f7f7f7;
  padding: 30px 30px 30px; }
  .pane-career-opportunities-panel-pane-2 .pane-title {
    margin-bottom: 20px; }

.pane-events-panel-pane-2 {
  padding: 30px 0; }

/**
 * Styles targeting the Spotlight content type
 *
 */
/* =Spotlight Teaser Sections
 ----------------------------------------------------------*/
.related-spotlight-teasers {
  background: #f7f7f7;
  margin: 20px 0 0;
  padding: 30px 0; }
  .related-spotlight-teasers .view-header h3 {
    text-align: center;
    margin: 0 0 30px; }
  .related-spotlight-teasers .view-content {
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px; }
    .related-spotlight-teasers .view-content:before, .related-spotlight-teasers .view-content:after {
      content: " ";
      display: table; }
    .related-spotlight-teasers .view-content:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .related-spotlight-teasers .view-content {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .related-spotlight-teasers .view-content {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .related-spotlight-teasers .view-content {
        width: 1170px; } }
  .related-spotlight-teasers .view-footer .btn--wcm {
    padding-right: 35px; }

.spotlight {
  background: #fff;
  border: 1px solid #dddddd;
  text-align: center;
  margin: 0 0 30px;
  padding: 20px;
  height: 100%; }

.spotlight__image {
  margin: 10px 0;
  padding: 20px;
  overflow: hidden; }
  .spotlight__image img {
    transition: all 0.3s ease 0s; }
    .spotlight__image img:hover {
      transform: scale(1.1); }

.spotlight__list-view {
  text-align: left; }
  .spotlight__list-view .row.is-flex {
    align-items: center; }
  .spotlight__list-view .spotlight__image {
    padding: 0; }
  .spotlight__list-view .spotlight__title {
    font-size: 20px;
    font-family: "1898Sans-Bold", sans-serif;
    color: #b31b1b; }
  .spotlight__list-view .spotlight__program {
    color: #666;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.3;
    font-family: "1898Sans-Bold", sans-serif;
    letter-spacing: 2px;
    margin: 10px 0; }

.spotlight__program span {
  font-weight: 700; }

.spotlight__teaser {
  font-size: 14px; }

/* =Spotlight Full Node
----------------------------------------------------------*/
.panel-wcm-hpr-spotlight .sidebar .pane-entity-field {
  margin: 0 0 10px; }
  .panel-wcm-hpr-spotlight .sidebar .pane-entity-field .field-label {
    font-weight: 700; }

.pane-node-field-spotlight-type {
  font-weight: 700; }

/**
 * Styles targeting the Event content type displays
 *
 */
/* =Home Event Flip Cards
 ----------------------------------------------------------*/
.hpr-home-events {
  position: relative; }
  .hpr-home-events h2 {
    margin: 10px 0; }
  @media screen and (min-width: 992px) {
    .hpr-home-events .see-all {
      position: absolute;
      top: 12px;
      right: 50px; } }

.hpr-home-events-panel {
  background-image: url(../images/home_panels/home-events-bg.jpg);
  background-size: cover;
  padding: 60px 30px; }
  .hpr-home-events-panel .row.is-flex {
    display: block; }
    @media screen and (min-width: 992px) {
      .hpr-home-events-panel .row.is-flex {
        display: flex;
        flex-wrap: wrap; } }

.hpr-home-event {
  margin: 0 0 20px; }
  @media screen and (min-width: 992px) {
    .hpr-home-event {
      margin: 0; } }

.home-event__date {
  width: 120px;
  margin: 0 auto 10px; }

.home-event__date__circle {
  font-family: "1898Sans-Bold", sans-serif;
  background: #dddddd;
  display: table-cell;
  height: 120px;
  width: 120px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%; }
  .home-event__date__circle span {
    display: block; }

.home-event__date___day {
  font-size: 30px; }

.home-event__date___month {
  font-size: 18px;
  position: relative;
  line-height: 0.5; }

.home-event__title {
  font-size: 14px;
  font-weight: 700;
  color: #b31b1b; }

.home-event__detail {
  margin: 0 0 10px; }

.home-event__detail:before {
  content: '';
  height: 20px;
  width: 30px;
  display: inline-block;
  position: relative;
  top: 5px; }

.home-event__location:before {
  background: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/location.svg") center left no-repeat;
  background-size: 20px 20px; }

.home-event__full-date:before {
  background: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg") center left no-repeat;
  background-size: 20px 20px; }

.home-event__time:before {
  background: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/appointment.svg") center left no-repeat;
  background-size: 20px 20px; }

.home-event__link {
  margin: 30px 0 0; }
  .home-event__link .btn {
    padding: 4px 20px; }
    .home-event__link .btn:hover {
      background: #b31b1b; }

/* =Flip Interaction
----------------------------------------------------------*/
.wcm-flip-card {
  perspective: 1000px; }

.wcm-flip-card.flip .wcm-flip-card__action {
  transform: rotateY(180deg); }

.wcm-flip-card,
.wcm-flip-card__front,
.wcm-flip-card__back {
  width: 100%; }

/* flip speed goes here */
.wcm-flip-card__action {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer; }

/* hide back of pane during swap */
.wcm-flip-card__front,
.wcm-flip-card__back {
  backface-visibility: hidden;
  padding: 30px 20px; }

/* front pane, placed above back */
.wcm-flip-card__front {
  transform: rotateY(0deg);
  background: #fff;
  z-index: 2;
  position: relative;
  text-align: center;
  min-height: 250px; }
  .wcm-flip-card__front:before {
    content: '';
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/information.svg") center left no-repeat;
    background-size: 20px 20px; }

/* back, initially hidden pane */
.wcm-flip-card__back {
  background: #F5F5F5;
  transform: rotateY(-180deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .wcm-flip-card__back:before {
    content: '';
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/close.svg") center left no-repeat;
    background-size: 20px 20px; }

/* =Upcoming Events List
 ----------------------------------------------------------*/
.event-list-item {
  margin: 20px 0 0; }
  .event-list-item:before, .event-list-item:after {
    content: " ";
    display: table; }
  .event-list-item:after {
    clear: both; }

.event-list-item:last-child {
  margin-bottom: 20px; }

.event-list-item__date {
  float: left;
  width: 12%;
  font-size: 18px;
  font-family: "1898Sans-Bold", sans-serif;
  color: #555555;
  border-right: 1px solid #dddddd; }

.event-list-item__title {
  float: left;
  width: 88%;
  padding-left: 18px; }

/* =Calendar Pager
 ----------------------------------------------------------*/
.wcm-calendar__pager {
  margin: 20px 0; }
  .wcm-calendar__pager .date-nav {
    padding: 0; }

@media screen and (max-width: 767px) {
  .item-list .pager--wcm-calendar {
    margin: 20px 0;
    padding: 0;
    text-align: left; } }

.view .wcm-calendar__pager .date-heading {
  text-align: left; }

.view .wcm-calendar__pager .date-prev,
.view .wcm-calendar__pager .date-next {
  border: none;
  background: none;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .view .wcm-calendar__pager .date-prev,
    .view .wcm-calendar__pager .date-next {
      position: static;
      margin: 0 10px 0 0; } }
  .view .wcm-calendar__pager .date-prev a,
  .view .wcm-calendar__pager .date-next a {
    color: #fff;
    background-color: #cf4520;
    border-color: #cf4520;
    font-weight: normal; }
    @media screen and (max-width: 767px) {
      .view .wcm-calendar__pager .date-prev a,
      .view .wcm-calendar__pager .date-next a {
        margin: 0; } }
    .view .wcm-calendar__pager .date-prev a:hover,
    .view .wcm-calendar__pager .date-next a:hover {
      background-color: #b31b1b;
      border-color: #b31b1b; }

.view .wcm-calendar__pager .date-prev {
  right: 85px; }

/* =Calendar Page Grid
----------------------------------------------------------*/
.wcm-calendar__page .calendar-calendar td.empty {
  color: #777; }

.wcm-calendar__page .calendar-calendar .month-view .full td.date-box.today,
.wcm-calendar__page .calendar-calendar .month-view .full tr td.single-day.today {
  border-color: #aaa; }

.wcm-calendar__page .calendar-calendar .month-view .full td.single-day div.monthview {
  border-left: 3px solid #e7751d;
  padding: 5px; }

.wcm-calendar__page .calendar-calendar .month-view .full .inner .monthview {
  border-radius: 0px; }

/* =Calendar Footer
----------------------------------------------------------*/
.wcm-calendar__footer {
  margin: 30px 0; }

.wcm-calendar__footer__links a {
  display: block;
  margin: 10px 0;
  border: none; }
  .wcm-calendar__footer__links a:before {
    content: '';
    height: 20px;
    width: 30px;
    display: inline-block;
    position: relative;
    top: 5px;
    background: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg") center left no-repeat;
    background-size: 20px 20px; }

.wcm-calendar__footer__contact > strong {
  color: #cf4520; }

/* =Calendar Responsive Styles (from Ken J.)
----------------------------------------------------------*/
/*CALENDAR MOBILE VIEW*/
/*NAVIGATION*/
#cal-nav-container h3.cal-title {
  text-align: center;
  padding-bottom: 10px; }
  #cal-nav-container h3.cal-title a {
    padding: 0px 50px;
    text-decoration: none; }
    @media all and (max-width: 768px) {
      #cal-nav-container h3.cal-title a {
        padding: 0px 5px; } }
  #cal-nav-container h3.cal-title a:after {
    content: none; }

.calendar-calendar .month-view .full td.single-day div.monthview {
  padding: 5px; }

.calendar-calendar td.empty {
  color: #666666; }

.calendar-calendar .month-view .full td.date-box.today {
  border-width: 2px 2px 0px 2px;
  border-style: solid;
  border-color: #cf4520; }
  @media all and (max-width: 768px) {
    .calendar-calendar .month-view .full td.date-box.today {
      border: none; } }

.calendar-calendar .month-view .full tr td.single-day.today {
  border-bottom: 2px solid #cf4520; }
  @media all and (max-width: 768px) {
    .calendar-calendar .month-view .full tr td.single-day.today {
      border: none; } }

.calendar-calendar .month-view .full tr td.today {
  background: none;
  border-left: 2px solid #cf4520;
  border-right: 2px solid #cf4520; }

.calendar-calendar .month-view .full td.multi-day div.monthview {
  color: #cf4520; }

.calendar-calendar td .inner div.day a {
  color: #cf4520; }

@media all and (max-width: 768px) {
  /* BEGIN Calendar responsive CSS - tablet portrait */
  /* Force table to not be like tables anymore */
  .calendar-calendar table,
  .calendar-calendar tbody,
  .calendar-calendar th,
  .calendar-calendar td,
  .calendar-calendar tr {
    display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  .calendar-calendar thead,
  .calendar-calendar thead tr,
  .calendar-calendar tr.date-box {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .calendar-calendar tr td.no-entry {
    border-bottom: 1px solid #ccc; }
  .calendar-calendar td:before {
    /* Format & Label the day headings */
    /* Now like a table header */
    width: 100%;
    white-space: nowrap;
    clear: both;
    text-align: center;
    display: block;
    box-sizing: border-box;
    color: #fff;
    background-color: #888;
    padding: 1px;
    content: attr(data-label);
    height: auto; }
  .calendar-calendar td.today,
  .calendar-calendar .full tr td.single-day.today {
    border-top: 2px solid #0074ab;
    -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25); }
  .calendar-calendar .full td, .calendar-calendar .month-view .full td, .calendar-calendar .week-view .full td, .calendar-calendar .day-view td {
    padding: 0; }
  .calendar-calendar td,
  .calendar-calendar td.calendar-agenda-items {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    width: 100%; }
  /*.calendar-calendar td.past{
    display: none;
  }*/
  .calendar-calendar td.no-entry,
  .calendar-calendar td.empty {
    display: none; }
  .calendar-calendar td.calendar-agenda-items div.calendar {
    padding: 0; }
  .calendar-calendar div.calendar,
  .calendar-calendar .date-display-single,
  .calendar-calendar td span.date-display-single {
    font-size: 14px;
    font-weight: normal;
    margin: 0 !important; }
  .calendar-calendar .inner .item {
    padding: 10px 10px 0; }
  .calendar-calendar .inner .item:last-child {
    padding-bottom: 10px; }
  .calendar-calendar .full tr.single-day .no-entry {
    height: 44px !important;
    line-height: 24px; }
  .calendar-calendar .full tr.single-day .no-entry .inner,
  .calendar-calendar .no-entry .inner {
    height: auto !important;
    line-height: 1px; }
  .calendar-calendar .inner .views-field-title-field {
    display: inline-block; }
  /* END Calendar responsive CSS - tablet portrait */ }

/**
 * Styles targeting various Landing Callouts
 *
 */
/* =Graduate Program Grid
 ----------------------------------------------------------*/
.hpr-programs-grid {
  background: #f7f7f7; }

.hpr-programs-grid__title {
  background: #cf4520;
  color: #fff;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 8px 0;
  text-align: center; }

.hpr-programs-grid__list {
  background: #fff; }

.hpr-programs-grid__list-item {
  padding: 30px;
  border: 1px solid #dddddd;
  height: 100%; }

/* =Band Callout
----------------------------------------------------------*/
.hpr-band-callout {
  margin: 30px 0;
  border: 1px solid #dddddd; }
  .hpr-band-callout .row.is-flex {
    align-items: center; }
  .hpr-band-callout h3 {
    margin-top: 0; }

.hpr-band-callout__img {
  overflow: hidden; }
  .hpr-band-callout__img img {
    transition: all 0.3s ease 0s; }
    .hpr-band-callout__img img:hover {
      transform: scale(1.1); }

.hpr-band-callout__text {
  padding: 0 20px; }

/* =Grad Programs Resources
----------------------------------------------------------*/
.grad-programs-resources h4 {
  margin-bottom: 15px; }

.grad-programs-resources .wcm-cta {
  width: 100%;
  margin: 20px 0; }

.grad-programs-resources .wcm-cta__tile {
  width: 50%; }

/* =Misc Overrides
----------------------------------------------------------*/
.panel-wcm-hpr-landing img.panopoly-image-full {
  margin: 0; }

/**
 * Styles targeting various components such as the breadcrumbs or sidebar items
 *
 */
/* =Primary Navigation
----------------------------------------------------------*/
#primary-nav > .menu {
  display: flex;
  justify-content: space-between; }

#primary-nav .level-1 {
  width: auto;
  font-size: 13px;
  line-height: 1.3; }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      font-size: 14px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      width: auto;
      font-size: 16px; } }

/* =Drawer Navigation
----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  #drawer-nav .level-1 > .menu {
    display: flex;
    flex-flow: row wrap; } }

@media screen and (min-width: 768px) {
  #drawer-nav .level-2 {
    padding: 10px 10px 0 0;
    width: 20%; } }

#top-nav .menu-mlid-1973 .level-3 ul {
  display: none; }

/* =Sidebar Social Media Callout
----------------------------------------------------------*/
.wcm-cta {
  width: 220px;
  margin: 0 auto 20px; }

.wcm-cta--social {
  text-align: center;
  font-weight: 700;
  margin: 0 auto 30px; }
  .wcm-cta--social .wcm-cta__tile {
    width: 30px;
    height: 30px;
    border: none;
    float: none;
    display: inline-block;
    margin-right: 10px;
    top: 10px; }
    .wcm-cta--social .wcm-cta__tile:after {
      box-shadow: none; }
    .wcm-cta--social .wcm-cta__tile .cta-icon {
      width: 30px;
      height: 30px; }

/* =Career Opportunities
----------------------------------------------------------*/
.view-career-opportunities .opportunity {
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #dddddd; }

.view-career-opportunities .opportunity:last-child {
  border: none; }

/* =Landing Lists
----------------------------------------------------------*/
.landing-list .landing-list-item {
  background: #f7f7f7; }
  @media screen and (min-width: 768px) {
    .landing-list .landing-list-item {
      border: 1px solid #dddddd;
      border-width: 0 1px 1px 0; }
      .landing-list .landing-list-item:before, .landing-list .landing-list-item:after {
        content: " ";
        display: table; }
      .landing-list .landing-list-item:after {
        clear: both; } }

@media screen and (min-width: 768px) {
  .landing-list .landing-list-item:first-child {
    border-width: 1px 1px 1px 0; }
    .landing-list .landing-list-item:first-child:before, .landing-list .landing-list-item:first-child:after {
      content: " ";
      display: table; }
    .landing-list .landing-list-item:first-child:after {
      clear: both; } }

.landing-list .teaser__detail {
  padding: 20px;
  font-size: 13px; }
  @media screen and (min-width: 1200px) {
    .landing-list .teaser__detail {
      padding: 30px;
      font-size: 14px; } }
  .landing-list .teaser__detail .landing-list-item__title {
    font-size: 18px;
    margin: 0 0 10px; }

.teaser__image {
  overflow: hidden; }
  .teaser__image img {
    transition: all 0.3s ease 0s; }
    @media screen and (min-width: 992px) {
      .teaser__image img:hover {
        transform: scale(1.1); } }

/* =View List Items
----------------------------------------------------------*/
.view-list-item {
  margin: 0 0 20px;
  padding: 20px;
  border: 1px solid #dddddd; }
  .view-list-item:before, .view-list-item:after {
    content: " ";
    display: table; }
  .view-list-item:after {
    clear: both; }
  .view-list-item .profile-list-image {
    overflow: hidden; }
    .view-list-item .profile-list-image img {
      width: 100%;
      height: auto; }
  .view-list-item .view-list-item__title {
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 5px; }
  .view-list-item .btn--small {
    margin: 10px 0; }

.directory-entry__title ul {
  margin: 0;
  padding: 0; }
  .directory-entry__title ul li {
    list-style-type: none;
    padding: 0 0 5px;
    margin: 0; }

.directory-entry__email {
  margin: 0 0 10px; }

/* =News
----------------------------------------------------------*/
.research-highlight__category {
  margin: 20px 0 0; }

/* =Research Programs
----------------------------------------------------------*/
.research-program-list .teaser__image {
  padding: 30px 60px 30px 0; }

.research-program-list .program {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd; }

.research-program-list .program:last-child {
  border-bottom: none; }

/* =Course Lists
----------------------------------------------------------*/
.view-courses .view-content > h3 {
  margin-bottom: 10px; }

/* =Biblio / Publications
----------------------------------------------------------*/
.node-biblio .submitted {
  display: none; }

.view-publications .view-content > h3 {
  color: #000000;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  line-height: 25px;
  font-weight: 700;
  background-color: #e1e1e1;
  border: 1px solid #ccc;
  padding: 7px;
  margin: 15px 0; }

.view-publications .publication {
  margin: 15px 0; }

/* =FAQ
----------------------------------------------------------*/
.q-and-a .q-and-a__label {
  font-weight: bold;
  color: #555555; }

/* =Course List
----------------------------------------------------------*/
.course__code {
  color: #666666; }
  @media screen and (min-width: 768px) {
    .course__code {
      float: right;
      padding: 0 20px 0 0; } }

/* =CTA Icons
----------------------------------------------------------*/
.wcm-cta__tile:nth-child(5) {
  border-width: 1px 1px 0 0; }

.wcm-cta__tile:nth-child(6) {
  border-width: 1px 0 0 0; }

/* =Footer
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px; } }

.footer-nav li {
  line-height: 1.4;
  padding: 0 0 8px; }

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 20%;
    float: left;
    padding: 0 10px 5px; } }

/* =Forms
----------------------------------------------------------*/
.views-exposed-form {
  background: #f7f7f7;
  padding: 10px 20px;
  margin: 0 0 20px; }
  @media screen and (min-width: 992px) {
    .views-exposed-form .form-select {
      max-width: none;
      margin-right: 45px; } }

.expand-toggle {
  font-size: 13px;
  margin: 30px 0 40px; }
  .expand-toggle button {
    background: #cf4520;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 6px 20px;
    margin-right: 5px; }

/* =Page-specific Adjustments
----------------------------------------------------------*/
.pane-bundle-table .pane-title {
  font-size: 18px;
  color: #555555;
  margin-bottom: 10px; }

.pane-divisions-panel-pane-2 {
  margin: 0 0 30px; }

/* =Misc
----------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .title-bar {
    line-height: 1.5; } }

@media screen and (max-width: 767px) {
  .menu-mlid-1589 > a {
    width: 80%;
    display: inline-block; } }

h3 a:hover {
  color: #cf4520; }

.teaser__image a {
  border-bottom: none; }

.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

/**
 * Styles targeting the home page.
 *
 */
/* =Home Panels
 ----------------------------------------------------------*/
@media screen and (min-width: 1400px) {
  .hpr-home-panels {
    max-width: 1400px;
    margin: 0 auto; } }

.hpr-home-panel {
  position: relative;
  overflow: hidden;
  display: none; }
  @media screen and (min-width: 992px) {
    .hpr-home-panel {
      display: block; } }
  .hpr-home-panel:hover .hpr-home-panel__top-links {
    transform: translate(0, 0); }

.hpr-home-panel__image img {
  width: 100%;
  height: auto; }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #cf4520; }

.hpr-home-panel__top-links {
  position: absolute;
  color: #fff;
  z-index: 100;
  width: 90%;
  transition: 0.25s;
  transform: translate(0, -100%); }
  .hpr-home-panel__top-links ul {
    margin: 10px 0 0;
    padding: 0 0 0 20px; }
    @media screen and (min-width: 1200px) {
      .hpr-home-panel__top-links ul {
        margin: 30px 0 0;
        padding: 0 0 0 30px; } }
  .hpr-home-panel__top-links li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 0 0 1px;
    position: relative; }
  .hpr-home-panel__top-links a {
    display: block;
    padding: 2px 15px 2px 8px;
    border: none;
    color: #fff;
    font-size: 14px; }
    @media screen and (min-width: 1200px) {
      .hpr-home-panel__top-links a {
        font-size: 15px;
        padding: 5px 20px 5px 8px; } }
    @media screen and (min-width: 1400px) {
      .hpr-home-panel__top-links a {
        padding: 10px 20px 10px 8px; } }
    .hpr-home-panel__top-links a:after {
      content: '\e80d';
      font-size: 60%;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none;
      line-height: 1.5;
      position: absolute;
      top: 33%;
      right: 5px; }
    .hpr-home-panel__top-links a:hover {
      background: #b31b1b; }

.hpr-home-panel__text {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  color: #fff;
  opacity: 0.87;
  background-color: #cf4520;
  text-align: center;
  z-index: 100;
  transition: height 0.25s; }
  .hpr-home-panel__text .hpr-home-panel__title {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */ }
    .hpr-home-panel__text .hpr-home-panel__title > a {
      display: block;
      font-size: 20px;
      line-height: 1.3;
      font-family: "1898Sans-Bold", sans-serif;
      color: #fff;
      position: relative;
      z-index: 100;
      margin: 0 30px;
      border-bottom: 1px solid #cf4520; }
      @media screen and (min-width: 1200px) {
        .hpr-home-panel__text .hpr-home-panel__title > a {
          font-size: 24px; } }

.hpr-home-panel__intro {
  transition: 0.25s;
  transform: translate(0, 200%);
  padding: 10px 20px;
  font-size: 13px; }
  @media screen and (min-width: 1200px) {
    .hpr-home-panel__intro {
      padding: 10px 30px 30px;
      font-size: 15px; } }

/* =Hover Interaction
----------------------------------------------------------*/
.hpr-home-panel:hover .mask {
  opacity: 0.87; }

.hpr-home-panel:hover .hpr-home-panel__top-links {
  transform: translate(0, 0); }

.hpr-home-panel:hover .hpr-home-panel__text {
  height: auto;
  max-height: auto;
  background-color: transparent; }

.hpr-home-panel:hover .hpr-home-panel__title {
  top: 0;
  transform: translateY(0); }
  .hpr-home-panel:hover .hpr-home-panel__title > a {
    border-bottom: 1px dotted #fff; }

.hpr-home-panel:hover .hpr-home-panel__intro {
  transform: translate(0, 0); }

/* =Simplified Home Panels for Mobile Devices
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .hpr-home-panels--sm {
    display: none; } }

.hpr-home-panel--sm {
  background: #fff;
  border: 1px solid #dddddd;
  margin: 0 0 30px;
  padding: 20px; }
  .hpr-home-panel--sm .row.is-flex {
    align-items: center; }

.hpr-home-panel--sm__image {
  margin: 10px 0; }

.hpr-home-panel--sm__title {
  font-size: 20px;
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b; }

.hpr-home-panel--sm__teaser {
  font-size: 14px; }

/* =Director's Message
----------------------------------------------------------*/
.hpr-chair-callout {
  margin: 30px 0; }
  .hpr-chair-callout .row.is-flex {
    align-items: center; }
  .hpr-chair-callout h3 {
    margin: 0 0 20px; }

.hpr-chair-callout__img {
  overflow: hidden; }
  .hpr-chair-callout__img img {
    transition: all 0.3s ease 0s; }
    .hpr-chair-callout__img img:hover {
      transform: scale(1.1); }
